var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claimGridTbody NewClaimRow"},_vm._l((_vm.header),function(col,i){return _c('div',{key:i,staticClass:"bodyItem text-center",class:{
      claimBorderLeft:
        col.value !== 'order' &&
        col.value !== 'arise_checkbox' &&
        col.value !== 'pass_checkbox',
      claimBorderRight:
        col.value === 'arise_checkbox' || col.value === 'pass_checkbox',
    }},[(col.value === 'order')?[_c('v-autocomplete',{attrs:{"dense":"","hide-details":"","items":_vm.orderList,"item-text":"order","return-object":""},model:{value:(_vm.claimNewRows.order),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "order", $$v)},expression:"claimNewRows.order"}})]:_vm._e(),(col.value === 'liaison')?[_c('v-autocomplete',{attrs:{"dense":"","hide-details":"","items":_vm.userList,"item-text":"name","return-object":""},model:{value:(_vm.claimNewRows.liaison),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "liaison", $$v)},expression:"claimNewRows.liaison"}})]:_vm._e(),(col.value === 'arise_month')?[_c('div',{staticClass:"pt-0 pb-0"},[_c('v-menu',{ref:"menu_date_register",refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.claimNewRows.arise_month,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.claimNewRows, "arise_month", $event)},"update:return-value":function($event){return _vm.$set(_vm.claimNewRows, "arise_month", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"centered-input",attrs:{"dense":"","attrs":attrs,"hide-details":"","readonly":""},model:{value:(_vm.claimNewRows.arise_month),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_month", $$v)},expression:"claimNewRows.arise_month"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu_date_register),callback:function ($$v) {_vm.menu_date_register=$$v},expression:"menu_date_register"}},[_c('MonthPicker',{on:{"input":function($event){return _vm.$refs.menu_date_register[0].save(_vm.claimNewRows.arise_month)}},model:{value:(_vm.claimNewRows.arise_month),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_month", $$v)},expression:"claimNewRows.arise_month"}})],1)],1)]:_vm._e(),(col.value === 'arise_type')?[_c('v-select',{staticClass:"claimTextField",attrs:{"items":_vm.claimType,"dense":"","hide-details":"","item-text":"label","item-value":"value"},model:{value:(_vm.claimNewRows.arise_type),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_type", $$v)},expression:"claimNewRows.arise_type"}})]:_vm._e(),(col.value === 'arise_client')?[_c('v-autocomplete',{attrs:{"return-object":"","dense":"","hide-details":"","items":_vm.$store.state.useClientList,"item-text":"name","filter":_vm.searchClientFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client)) : "")+" ")]}}],null,true),model:{value:(_vm.claimNewRows.arise_client),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_client", $$v)},expression:"claimNewRows.arise_client"}})]:_vm._e(),(col.value === 'arise_price')?[_c('div',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function (e) { return (_vm.claimNewRows.arise_price = _vm.usingComma(e)); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-select',{ref:"salesClaimTable",refInFor:true,staticClass:"amountSelect pt-0 pr-0",attrs:{"items":_vm.$store.state.currency_sign,"item-text":"label","item-value":"value","hide-details":"","flat":"","solo":"","hide-selected":"","id":"claimCurrency"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true),model:{value:(_vm.claimNewRows.arise_currency_sign),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_currency_sign", $$v)},expression:"claimNewRows.arise_currency_sign"}})]},proxy:true}],null,true),model:{value:(_vm.claimNewRows.arise_price),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_price", $$v)},expression:"claimNewRows.arise_price"}}),(_vm.claimNewRows.arise_currency_sign !== '₩')?_c('div',{staticClass:"eschamgeCell"},[_c('v-text-field',{attrs:{"hide-details":"","label":"환율"},on:{"input":function (e) { return (_vm.claimNewRows.arise_eschamge = _vm.usigComnma(e)); }},model:{value:(_vm.claimNewRows.arise_eschamge),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_eschamge", $$v)},expression:"claimNewRows.arise_eschamge"}})],1):_vm._e()],1)]:_vm._e(),(col.value === 'arise_passtype')?[_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"claimTextField",attrs:{"items":_vm.passType,"dense":"","hide-details":"","item-value":"value"},on:{"change":function($event){return _vm.passTypeChange($event, _vm.claimNewRows, 'new')}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('v-icon',{attrs:{"color":item.value === 1
                    ? 'orange'
                    : item.value === 2
                    ? 'red'
                    : item.value === 3
                    ? 'green'
                    : ''}},[_vm._v("mdi-circle-slice-8")])],1)]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-icon',{attrs:{"color":item.value === 1
                    ? 'orange'
                    : item.value === 2
                    ? 'red'
                    : item.value === 3
                    ? 'green'
                    : ''}},[_vm._v("mdi-circle-slice-8")])],1),_vm._v(" "+_vm._s(item.label)+" ")]}}],null,true),model:{value:(_vm.claimNewRows.arise_passtype),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_passtype", $$v)},expression:"claimNewRows.arise_passtype"}})],1)]:_vm._e(),(col.value === 'arise_detail')?[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"hide-details":"","dense":""},model:{value:(_vm.claimNewRows.arise_detail),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "arise_detail", $$v)},expression:"claimNewRows.arise_detail"}})],1)]:_vm._e(),(col.value === 'arise_status')?[_c('span',[_vm._v(" 생성 ")])]:_vm._e(),(col.value === 'ariseNone')?[_c('span',[_c('v-btn',{attrs:{"color":"green","small":"","disabled":_vm.$store.state.isExpired},on:{"click":function($event){return _vm.saveClaim(_vm.claimNewRows, 'create', 'arise')}}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("등록")])])],1)]:_vm._e(),(col.value === 'pass_month')?[_c('div',{staticClass:"pt-0 pb-0"},[_c('v-menu',{ref:"pass_menu_date_register",refInFor:true,attrs:{"close-on-content-click":false,"return-value":_vm.claimNewRows.pass_month,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.claimNewRows, "pass_month", $event)},"update:return-value":function($event){return _vm.$set(_vm.claimNewRows, "pass_month", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"centered-input",attrs:{"dense":"","attrs":attrs,"hide-details":"","readonly":""},model:{value:(_vm.claimNewRows.pass_month),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_month", $$v)},expression:"claimNewRows.pass_month"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.pass_menu_date_register),callback:function ($$v) {_vm.pass_menu_date_register=$$v},expression:"pass_menu_date_register"}},[_c('MonthPicker',{on:{"input":function($event){return _vm.$refs.pass_menu_date_register[0].save(_vm.claimNewRows.pass_month)}},model:{value:(_vm.claimNewRows.pass_month),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_month", $$v)},expression:"claimNewRows.pass_month"}})],1)],1)]:_vm._e(),(col.value === 'pass_type')?[_c('v-select',{staticClass:"claimTextField",attrs:{"items":_vm.claimType,"dense":"","hide-details":"","item-text":"label","item-value":"value"},model:{value:(_vm.claimNewRows.pass_type),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_type", $$v)},expression:"claimNewRows.pass_type"}})]:_vm._e(),(col.value === 'pass_client')?[_c('v-autocomplete',{attrs:{"dense":"","hide-details":"","return-object":"","items":_vm.$store.state.useClientList,"item-text":"name","filter":_vm.searchClientFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.bill_client ? (" - " + (item.bill_client)) : "")+" ")]}}],null,true),model:{value:(_vm.claimNewRows.pass_client),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_client", $$v)},expression:"claimNewRows.pass_client"}})]:_vm._e(),(col.value === 'pass_price')?[_c('div',{staticClass:"pt-0 pb-0"},[_c('v-text-field',{attrs:{"hide-details":"","dense":""},on:{"input":function (e) { return (_vm.claimNewRows.pass_price = _vm.usingComma(e)); }},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-select',{ref:"salesClaimTable",refInFor:true,staticClass:"amountSelect pt-0 pr-0",attrs:{"items":_vm.$store.state.currency_sign,"item-text":"label","item-value":"value","hide-details":"","flat":"","solo":"","hide-selected":"","id":"claimCurrency"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.label)+" ")])]}}],null,true),model:{value:(_vm.claimNewRows.pass_currency_sign),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_currency_sign", $$v)},expression:"claimNewRows.pass_currency_sign"}})]},proxy:true}],null,true),model:{value:(_vm.claimNewRows.pass_price),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_price", $$v)},expression:"claimNewRows.pass_price"}})],1),(_vm.claimNewRows.pass_currency_sign !== '₩')?_c('div',{staticClass:"eschamgeCell"},[_c('v-text-field',{attrs:{"hide-details":"","label":"환율"},on:{"input":function (e) { return (_vm.item.pass_eschamge = _vm.usingComma(e)); }},model:{value:(_vm.claimNewRows.pass_eschamge),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_eschamge", $$v)},expression:"claimNewRows.pass_eschamge"}})],1):_vm._e()]:_vm._e(),(col.value === 'pass_kind')?[_c('v-select',{attrs:{"items":_vm.claimKind,"dense":"","hide-details":"","item-text":"label","item-value":"value"},model:{value:(_vm.claimNewRows.pass_kind),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_kind", $$v)},expression:"claimNewRows.pass_kind"}})]:_vm._e(),(col.value === 'pass_detail')?[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"hide-details":"","dense":""},model:{value:(_vm.claimNewRows.pass_detail),callback:function ($$v) {_vm.$set(_vm.claimNewRows, "pass_detail", $$v)},expression:"claimNewRows.pass_detail"}})],1)]:_vm._e(),(col.value === 'pass_status')?[_c('span',[_vm._v(" 생성 ")])]:_vm._e(),(col.value === 'passNone')?[_c('v-btn',{attrs:{"color":"green","small":"","disabled":_vm.$store.state.isExpired},on:{"click":function($event){return _vm.saveClaim(_vm.claimNewRows, 'create', 'pass')}}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("등록")])])]:[_c('div')]],2)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }